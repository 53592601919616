import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
    Col, Radio, Row, Form, Input, Select, Button, Modal,
    notification, Spin, Divider
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { useHistory } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { format, addDays } from 'date-fns';
import moment from 'moment';
import {
    radioOptionSystemAddUser,
    cadastroModalCampoNome,
    nameUserAdd,
    radioAtivoserAdd,
    emailUserAdd,
    celularUserAdd,
    passUserAdd,
    confirmPassUserAdd,
    radioButtomVendedorUserAdd,
    radioButtomObservacaoUserAdd,
    DiasDeTesteUserAdd,
    validarDispositivoUserAdd,
    primeiroAcessoLiberadoUserAdd,
    selectedCargo,
} from './GridStyle';
// import { FormatPainterOutlined } from '@ant-design/icons';
import * as UsuariosActions from '../../../../store/modules/usuario/actions';
import { UserState } from './../../../../interfaces/UserAdd';
import { useDispatch, useSelector } from 'react-redux';
// import InputTelefone from './../../../../components/InputTelefone';
import { mask, unMask } from '../../../../utils/MascaraDeCampos';
import axios from 'axios';
import { urlCargo, urlBuscaUserByEmail, urlBuscaUserByCelular, urlUsers } from '../../../../services/request/urls';
import { getToken } from './../../../../services/token';
import { convertDataUSParaBR, convertDataBrParaUS } from './../../../../services/Conversores';
import { removeAcento } from '../../../../utils/RemoveAcentos';

interface interfaceCargo {
    nome: string,
    id: number
}

const BotaoSelectAddUser: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const refDadosCargoState = useRef<Array<interfaceCargo>>([]);

    const formRef = useRef<any>(null);
    // const copyFormRef = useRef<FormInstance<'adicionatUsuarioStep1'>>(new );
    const inputDiasDeTeste = useRef<any>(null);
    const refFormModalAddCargo = useRef<FormInstance>(null);
    // const formRef: any = React.createRef();

    const propsState = useSelector((state: UserState) => state.usuario);

    const [value, setValue] = useState("app");
    // const [loadingFormAdd, setloadingFormAdd] = useState(false);
    // const [valTestador, setValTestador] = useState(false);
    // const [valorCampoInputDiasDeTeste, setValorCampoInputDiasDeTeste] = useState('');
    const [modalAddCargoVisible, setModalAddCargoVisible] = useState(false);
    const [loadingFormModalCargo, setLoadingFormModalCargo] = useState(false);
    const [dadosDoCargoList, setDadosDoCargoList] = useState<Array<interfaceCargo>>([]);
    const [estaEditando, setEstaEditando] = useState(false);
    const [vendedorValue, setVendedorValue] = useState(false);
    const [obrigatorioConfirm, setObrigatorioConfirm] = useState(false);

    const [selectedDatePickerMui, setSelectedDatePickerMui] = useState<any>(null);

    const buscarDadosCargosList = useCallback((setCampo?, nome?, selectIdAndSet?, id?) => {

        if (setCampo) {
            formRef.current?.setFieldsValue({ cargo: nome })
        }

        const buscaDadosCargo = async () => {
            try {
                let result = await axios.get(urlCargo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                refDadosCargoState.current = result.data;
                setDadosDoCargoList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceCargo) => {
                        if (val.id == id) {
                            nome = val.nome
                        }
                    });
                    formRef.current?.setFieldsValue(
                        { cargo: nome }
                    )
                }

            } catch (error) {
                console.error(error);
            }
        }

        buscaDadosCargo();

    }, []);

    const comonentDidMount = useEffect((): void => {
        //scroll sempre no topo
        window.scrollTo(0, 0);

        dispatch(UsuariosActions.formularioRefUsers(formRef));

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        // localUserData?.step4.horarioDasNotificacoes? localUserData?.step4.horarioDasNotificacoes: undefined,

        if (localUserData?.step1) {

            formRef.current?.setFieldsValue(
                {
                    ativo: localUserData?.step1.ativo,
                    confirmacaoSenha: localUserData?.step1.confirmacaoSenha ? localUserData?.step1.confirmacaoSenha : undefined,
                    email: localUserData?.step1.email ? localUserData?.step1.email : undefined,
                    nome: localUserData?.step1.nome ? localUserData?.step1.nome : undefined,
                    senha: localUserData?.step1.senha ? localUserData?.step1.senha : undefined,
                    sistema: localUserData?.step1.sistema ? localUserData?.step1.sistema : undefined,
                    observacao: localUserData?.step1.observacao ? localUserData?.step1.observacao : undefined,
                    // testador: localUserData?.step1.testador,
                    // validade_teste: localUserData?.step1?.validade_teste ? convertDataUSParaBR(localUserData?.step1.validade_teste) : undefined,
                    vendedor: localUserData?.step1.vendedor,
                }
            );

            // setSelectedDatePickerMui(localUserData?.step1?.validade_teste ? moment(localUserData?.step1.validade_teste) : null)

            // if (localUserData?.step1.testador) {
            //     setValTestador(true);
            // }
            if (localUserData?.step1?.senha?.length > 0) {
                setObrigatorioConfirm(true);
            }
            if (localUserData?.step1.cargo && localUserData?.step1.cargo?.id) {
                buscarDadosCargosList(null, null, true, localUserData?.step1?.cargo?.id);
            } else {
                buscarDadosCargosList();
            }
            if (localUserData.step1.sistema) {
                dispatch(UsuariosActions.userAddStepMenu(localUserData.step1.sistema));
            }
            if (localUserData?.step1.celular) {
                const originalValue = unMask(localUserData?.step1.celular);
                const maskedValue = mask(originalValue, [
                    // "(99) 9999-9999",
                    "(99) 9 9999-9999"
                ]);
                formRef.current?.setFieldsValue({
                    celular: maskedValue
                })
            }
            if (localUserData?.isEdit) {
                setEstaEditando(localUserData?.isEdit);
            }
        } else {
            //buscar dados do cargo porem para nao repetir requisição
            buscarDadosCargosList();

            dispatch(UsuariosActions.userAddStepMenu('app'));
            formRef.current?.setFieldsValue(
                {
                    sistema: 'app',
                }
            );
        }
    }, []);

    const comonentDidMountSetVal = useEffect((): void => {
        setValue("app");
    }, [value]);

    // const onChangeInputDiasDeTeste = useCallback((e: any) => {
    //     const originalValue = e.target.value;//+''
    //     const regex = /^[0-9]*$/

    //     if (regex.test(originalValue)) {
    //         setValorCampoInputDiasDeTeste(originalValue)
    //         formRef.current.setFieldsValue(
    //             { dias_de_teste: originalValue }
    //         );
    //     } else {
    //         formRef.current.setFieldsValue(
    //             { dias_de_teste: valorCampoInputDiasDeTeste }
    //         );
    //     }
    // }, [valorCampoInputDiasDeTeste]);

    // const onChangeValidade_teste = useCallback((e: any) => {
    //     const originalValue = unMask(e.target.value);
    //     const maskedValue = mask(originalValue, [
    //         "99/99/9999"
    //     ]);

    //     formRef.current?.setFieldsValue(
    //         { validade_teste: maskedValue }
    //     );

    // }, []);

    const onChange = useCallback((e): void => {
        dispatch(UsuariosActions.userAddStepMenu(e.target.value));
    }, []);

    const clickSubmitModalAddCargo = useCallback((): void => {
        refFormModalAddCargo.current?.validateFields()
            .then((values: any) => {
                setLoadingFormModalCargo(true);

                const salvarDadosModal = async () => {
                    try {
                        let resultReq = await axios.post(urlCargo, values,
                            {
                                headers: { 'Authorization': 'Bearer ' + getToken() }
                            });
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });

                        setLoadingFormModalCargo(false);
                        buscarDadosCargosList(true, resultReq.data.nome);
                        clickCancelModalAddCargo();
                    } catch (error) {
                        setLoadingFormModalCargo(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const clickCancelModalAddCargo = useCallback((): void => {
        setModalAddCargoVisible(false);
        refFormModalAddCargo.current?.resetFields();
    }, []);

    const onClickAddCargo = useCallback((e): void => {
        setModalAddCargoVisible(true);
    }, []);

    // const onChangeTestador = useCallback((e): void => {
    //     // setValTestador(e.target.value);
    //     // if (e.target.value) {
    //     //     setTimeout(() => {
    //     //         inputDiasDeTeste.current.focus();
    //     //     }, 200);
    //     // } else {
    //     //     formRef.current?.setFieldsValue({ validade_teste: undefined })
    //     // }
    //     let dataSomada = format(
    //         addDays(
    //             new Date(),
    //             7
    //         )
    //         , 'yyyy-MM-dd'
    //     )
    //     if (e.target.value) {
    //         setSelectedDatePickerMui(moment(dataSomada))
    //     } else {
    //         setSelectedDatePickerMui(null)
    //     }
    // }, []);

    const onFinish = useCallback((formValues): void => {
        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        let dadosLStorageAttSair: string | null = localStorage.getItem('@GovFacil:userDataAtualizarESair');
        let dataUserAttSair: any = null;
        if (typeof dadosLStorageAttSair == 'string') {
            dataUserAttSair = JSON.parse(dadosLStorageAttSair);
        }

        const editarDadosApenasDestaPg = async () => {
            dispatch(UsuariosActions.setSpinnerStep(true));
            try {

                //Salvando dados do usuario
                let resultDadosUser = await axios.put(
                    urlUsers + "/" + localUserData?.idUser,
                    localUserData.step1,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram atualizados com sucesso!',
                });
                dispatch(UsuariosActions.setSpinnerStep(false));
                history.push("/usuarios?at=1");
            } catch (error) {

                let msgErro: any = (error as Error);
                dispatch(UsuariosActions.setSpinnerStep(false));
                notification.error({
                    message: 'Erro',
                    description: msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Erro entre em contato com o suporte!',
                });
            }
        }
        if (localUserData?.isEdit === true) {
            if ((dataUserAttSair?.step1?.ativo === false) && (localUserData?.step1?.ativo === true)) {
                //usuario nao pode atualizar cadastro de ativo sem passar todas as steps
                notification.warning({
                    message: 'Atenção',
                    description:
                        'Para alterar status você deve selecionar o contrato!',
                });
            } else {
                editarDadosApenasDestaPg();
            }
        }

    }, []);

    const selectCargoPorName = useCallback((nome): number | null => {
        let idCargo = null;
        refDadosCargoState.current.forEach(val => {
            if (val.nome == nome) {
                idCargo = val.id
            }
        });
        return idCargo;

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            // "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        formRef.current?.setFieldsValue(
            { celular: maskedValue }
        );
        if (maskedValue.length == 16) {
            formRef.current?.validateFields(["celular"])
        }

    }, []);

    const validaEmail = useCallback((email: string): boolean => {
        let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);

        if (email == '' || !er.test(email)) { return false; }
        return true;

    }, []);

    const buscarEmailSeJaExiste = useCallback((email: string): Promise<boolean> => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlBuscaUserByEmail + email,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else if (result.data.id == localUserData.idUser) {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar email!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const buscarCelularSeJaExiste = useCallback((celular: string): Promise<boolean> => {

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlBuscaUserByCelular + celular,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else if (result.data.id == localUserData.idUser) {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar celular!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const onFieldsChange = useCallback((changedFields, allFields): void => {
        let obj: any = new Object;
        allFields.forEach((element: any) => {
            let name = element.name[0];
            let value = element.value;
            if (name == "celular" && value != undefined) {
                value = unMask(value);
            }
            if (name == "cargo") {
                value = { "id": selectCargoPorName(value) }
            }
            // if (name == "validade_teste") {
            //     value = convertDataBrParaUS(selectedDatePickerMui)
            // }
            obj[name] = value;
        });

        let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:userData');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
            localUserData = {
                ...localUserData,
                "step1": obj
            }
            localStorage.setItem('@GovFacil:userData', JSON.stringify(localUserData));
        } else {
            localStorage.setItem('@GovFacil:userData', JSON.stringify({ "step1": obj }));
        }
    }, [selectedDatePickerMui]);

    return (
        <>
            <Modal
                title="Adicionar cargo"
                className="modalAddCargo"
                visible={modalAddCargoVisible}
                onOk={clickSubmitModalAddCargo}
                onCancel={clickCancelModalAddCargo}
                footer={[
                    <Button key="back" onClick={clickCancelModalAddCargo}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" onClick={clickSubmitModalAddCargo}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormModalCargo}>
                    <Form
                        ref={refFormModalAddCargo}
                        name="formModalAddCargo"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col {...cadastroModalCampoNome}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite o nome do cargo!',
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Nome do cargo" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
            {/* <Spin spinning={loadingFormAdd}> */}
            <Form
                ref={formRef}
                name="adicionatUsuarioStep1"
                layout="vertical"
                onFieldsChange={onFieldsChange}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={() => { }}
                autoComplete="off"
            >
                <Row>
                    <Col {...radioOptionSystemAddUser}>
                        <Form.Item
                            initialValue={propsState.nome}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o tipo de usuario!',
                                }
                            ]}
                            name="sistema"
                            label="Sistema do usuário"
                        >
                            <Radio.Group onChange={onChange} value={propsState.nome}>
                                <Radio.Button value="app">App</Radio.Button>
                                {/* <Radio.Button value="painel">Painel</Radio.Button> */}
                                <Radio.Button value="notificacoes">Notificações</Radio.Button>
                                <Radio.Button value="todos">Todos</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col {...radioAtivoserAdd}>
                        <Form.Item
                            name="ativo"
                            label="Ativo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione se é ativo!',
                                },
                            ]}
                            initialValue={true}
                        >
                            <Radio.Group>
                                <Radio.Button value={true}>Sim</Radio.Button>
                                <Radio.Button value={false}>Não</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col {...nameUserAdd}>
                        <Form.Item
                            name="nome"
                            label="Nome"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor digite seu nome!',
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Nome" />
                        </Form.Item>
                    </Col>
                    <Col {...celularUserAdd}>
                        <Form.Item
                            name="celular"
                            label="Celular"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor digite seu celular",
                                    max: 16,
                                },
                                () => ({
                                    validator(rule, value) {
                                        return new Promise((resolve: (value?: any) => void, reject) => {
                                            //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                            if (value != '' && value != null) {
                                                value = unMask(value);
                                                if (value.length > 10) {
                                                    buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        if (valorPromessa) {
                                                            reject("Celular já existe!");
                                                        } else {
                                                            resolve();
                                                        }
                                                    }).catch(error => {
                                                        resolve();
                                                    })
                                                } else {
                                                    reject("Celular inválido");
                                                }
                                            } else {
                                                reject('');
                                            }
                                        });
                                    },
                                }),
                            ]}
                        >
                            <Input
                                autoComplete="off"
                                onChange={(e) => onChangeInputTelefone(e)}
                                placeholder="(44) 9 9999-9999"
                            />
                        </Form.Item>
                    </Col>
                    <Col {...selectedCargo}>
                        <Form.Item
                            name="cargo"
                            label="Cargo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o cargo!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                                dropdownRender={menu => (
                                    <div>

                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Button block onClick={onClickAddCargo} type="dashed">Adicionar Cargo</Button>
                                        </div>
                                        <Divider style={{ margin: '4px 0' }} />
                                        {menu}
                                    </div>
                                )}
                            >
                                {
                                    dadosDoCargoList.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.nome}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                                {/* <Select.Option value="prefeito">Prefeito</Select.Option>
                                    <Select.Option value="governador">Governador</Select.Option>
                                    <Select.Option value="contador">Contador</Select.Option>
                                    <Select.Option value="advogado">Advogado</Select.Option>
                                    <Select.Option value="secretario">Secretario</Select.Option>
                                    <Select.Option value="acessorPrefeito">Acessor - Prefeito</Select.Option>
                                    <Select.Option value="acessorGovernador">Acessor - Governador</Select.Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...emailUserAdd}>
                        <Form.Item
                            name="email"
                            label="Email (Login)"
                            rules={[
                                {
                                    required: propsState.nome == "painel" || propsState.nome == "todos"
                                        ?
                                        true
                                        :
                                        vendedorValue
                                    ,
                                    // required: ,
                                    message: 'Por favor digite seu email!',
                                },
                                () => ({
                                    validator(rule, value) {
                                        return new Promise((resolve: (value?: any) => void, reject) => {
                                            //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                            if (value != '' && value != null) {
                                                if (validaEmail(value)) {
                                                    buscarEmailSeJaExiste(value).then(valorPromessa => {
                                                        if (valorPromessa) {
                                                            reject("Email já existe!");
                                                        } else {
                                                            resolve();
                                                        }
                                                    }).catch(error => {
                                                        resolve();
                                                    })
                                                } else {
                                                    reject("Email inválido");
                                                }
                                            } else {
                                                if (propsState.nome == "painel" || propsState.nome == "todos") {
                                                    reject('');
                                                } else {
                                                    resolve();
                                                }

                                            }
                                        });
                                    },
                                }),
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Digite seu email!" />
                        </Form.Item>
                    </Col>
                    {propsState.nome == "painel" || propsState.nome == "todos" ?
                        <>
                            <Col {...passUserAdd}>
                                <Form.Item
                                    name="senha"
                                    label="Senha"
                                    rules={[
                                        {
                                            required: estaEditando === true ? false : true,
                                            message: 'Por favor digite sua senha!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value?.length >= 1) {
                                                    setObrigatorioConfirm(true);
                                                }
                                                if (value?.length == 0) {
                                                    setObrigatorioConfirm(false);
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Senha" />
                                </Form.Item>
                            </Col>
                            <Col {...confirmPassUserAdd}>
                                <Form.Item
                                    name="confirmacaoSenha"
                                    label="Confirme sua Senha"
                                    rules={[
                                        {
                                            required: obrigatorioConfirm,
                                            message: 'Por favor digite novamente sua senha!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('senha') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Senha está diferente da anterior!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Confirme sua senha!" />
                                </Form.Item>
                            </Col>
                        </>
                        : ""}
                </Row>


                {/* <Col {...radioButtomTestadorUserAdd}>
                                <Form.Item
                                    initialValue={false}
                                    name="testador"
                                    label="Testador"
                                    rules={[
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeTestador}>
                                        <Radio.Button value={true}>Sim</Radio.Button>
                                        <Radio.Button value={false}>Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}

                {/* <Col {...DiasDeTesteUserAdd}>
                                <Form.Item
                                    name="validade_teste"
                                    label="Data de validade"
                                    rules={[
                                        {
                                            // required: valTestador,
                                            required: false,
                                            message: "Por favor digite a data",
                                        },
                                        // () => ({
                                        //     validator(rule, value) {
                                        //         if (value != '' && value != null) {
                                        //             if (value.length < 10) {
                                        //                 return Promise.reject('Data Inválida');
                                        //             } else {
                                        //                 if (moment(value, 'DD/MM/YYYY', true).isValid()) {
                                        //                     return Promise.resolve();
                                        //                 } else {
                                        //                     return Promise.reject('Data Inválida');
                                        //                 }
                                        //             }

                                        //         } else {
                                        //             if (valTestador) {
                                        //                 return Promise.reject('');
                                        //             } else {
                                        //                 return Promise.resolve();
                                        //             }
                                        //         }
                                        //     },
                                        // }),
                                    ]}
                                >
                                    <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                        <DatePicker
                                            value={selectedDatePickerMui}
                                            onChange={setSelectedDatePickerMui}
                                            required={false}
                                            disabled={!valTestador}
                                            format="dd/MM/yyyy"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Form.Item>
                            </Col> */}

                {/* <Col {...validarDispositivoUserAdd}>
                                    <Form.Item
                                        initialValue={false}
                                        name="validar_dispositivo"
                                        label="Validar Dispositivo"
                                        rules={[
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Radio.Button value={true}>Sim</Radio.Button>
                                            <Radio.Button value={false}>Não</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> */}
                {/* {propsState.nome == "app" || propsState.nome == "todos" ? */}
                <>
                    <Row>
                        <Col {...radioButtomVendedorUserAdd} hidden={(propsState.nome == "app" || propsState.nome == "todos") != true}>
                            {/* <Col {...validarDispositivoUserAdd}> */}
                            <Form.Item
                                initialValue={false}
                                name="vendedor"
                                label="Vendedor"
                                rules={[
                                ]}
                            >
                                <Radio.Group
                                    onChange={(val: any) => { setVendedorValue(val.target.value) }}
                                >
                                    <Radio.Button value={true}>Sim</Radio.Button>
                                    <Radio.Button value={false}>Não</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            estaEditando === false ?
                                <Col {...radioButtomVendedorUserAdd}>
                                    <Form.Item
                                        initialValue={false}
                                        name="enviar_whatsapp"
                                        label="Enviar WhatsApp Cadastro"
                                        rules={[
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Radio.Button value={true}>Sim</Radio.Button>
                                            <Radio.Button value={false}>Não</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                :
                                <></>
                        }
                        <Col {...radioButtomObservacaoUserAdd}>
                            <Form.Item
                                name="observacao"
                                label="Observações"
                                rules={[
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
                {/* <Col {...NotificacaoAppUserAdd}>
                            <Form.Item
                                initialValue="nao"
                                name="notificacaoaplicativo"
                                label="Notificações Aplicativo"
                                rules={[
                                ]}
                            >
                                <Radio.Group>
                                    <Radio.Button value="sim">Sim</Radio.Button>
                                    <Radio.Button value="nao">Não</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col> */}
                {/* <Col {...relatorioDoPainelUserAdd}>
                            <Form.Item
                                initialValue="nao"
                                name="relatoriopainel"
                                label="Relatório do Painel"
                                rules={[
                                ]}
                            >
                                <Radio.Group>
                                    <Radio.Button value="sim">Sim</Radio.Button>
                                    <Radio.Button value="nao">Não</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...AcessoMsgAppPainelUserAdd}>
                            <Form.Item
                                initialValue="nao"
                                name="acessomsgappnopainel"
                                label="Mensagens do aplicativo no Painel"
                                rules={[
                                ]}
                            >
                                <Radio.Group>
                                    <Radio.Button value="sim">Sim</Radio.Button>
                                    <Radio.Button value="nao">Não</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col {...AcessoNotificacaoSMSEmailUserAdd}>
                            <Form.Item
                                initialValue="nao"
                                name="acessonotificacaosms"
                                label="Notificações de SMS e Email no Painel"
                                rules={[
                                ]}
                            >
                                <Radio.Group>
                                    <Radio.Button value="sim">Sim</Radio.Button>
                                    <Radio.Button value="nao">Não</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col> */}
                {/* <Col {...receberNotificacaoUserAdd}>
                                <Form.Item
                                    initialValue="nao"
                                    name="recebernotificacao"
                                    label="Receber Notificação"
                                    rules={[
                                    ]}
                                >
                                    <Radio.Group >
                                        <Radio.Button value="sim">Sim</Radio.Button>
                                        <Radio.Button value="nao">Não</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}
            </Form>
            {/* </Spin> */}
        </>
    );
}

export default BotaoSelectAddUser;
